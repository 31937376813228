<template>
    <td
        :style="{
            borderSpacing: '16px',
            verticalAlign: 'top',
            border: '1px solid #f3f6f8',
        }"
    >
        <!-- If an image -->
        <template v-if="item.props.media && item.props.media.url">
            <div class="media-item-container">
                <img
                    :src="
                        item.props.media.url.replace('?p=original', '') +
                        '?w=334'
                    "
                    style="
                        width: 100%;
                        max-width: 334px;
                        display: block;
                        border: 0px;
                    "
                    border="0"
                    height="auto"
                />
            </div>
        </template>

        <table
            v-else-if="item.props.media.before"
            cellpadding="0"
            cellspacing="0"
            width="100%"
            border="0"
            height="100%"
            style="height: 100%"
        >
            <tbody>
                <tr
                    :style="{
                        marginBottom: '16px',
                    }"
                >
                    <td
                        :style="{
                            width: '50%',
                            backgroundColor: '#f3f6f8',
                        }"
                    >
                        <div
                            :style="{
                                margin: '12px',
                                textAlign: 'center',
                            }"
                        >
                            <span v-html="item.props.media.before.label" />
                        </div>

                        <div
                            :style="{
                                width: '100%',
                                paddingTop: '56.25%',
                                backgroundPosition: 'center center',
                                backgroundSize: 'cover',
                                backgroundImage: `url(${item.props.media.before.url})`,
                            }"
                        />
                    </td>

                    <td
                        :style="{
                            width: '50%',
                            backgroundColor: '#f3f6f8',
                        }"
                    >
                        <div
                            :style="{
                                margin: '12px',
                                textAlign: 'center',
                            }"
                        >
                            <span v-html="item.props.media.after.label" />
                        </div>

                        <div
                            :style="{
                                width: '100%',
                                paddingTop: '56.25%',
                                backgroundPosition: 'center center',
                                backgroundSize: 'cover',
                                backgroundImage: `url(${item.props.media.after.url})`,
                            }"
                        />
                    </td>
                </tr>
            </tbody>
        </table>

        <div
            v-else
            :style="{
                backgroundColor: getColor('primary'),
                margin: '0 8px 8px 0',
                borderRadius: '4px',
                display: 'inline-block',
                height: '100%',
                border: '16px rgba(0,0,0,0)',
            }"
        >
            <a
                :href="'https://media.caapp.com.au/' + item.props.media.ref"
                target="_blank"
                :style="{
                    color: getColor('light'),
                    fontFamily: projectConfig.fontFamily,
                }"
            >
                Play Video
            </a>
        </div>

        <div v-if="item.props.caption">
            <div style="margin: 12px">
                <p :style="{ fontFamily: projectConfig.fontFamily }">
                    {{ item.props.caption }}
                </p>
            </div>
        </div>
    </td>
</template>

<script>
export default {
    name: 'GalleryItem',
    props: {
        item: Object,
    },
};
</script>
