import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _534af48b = () => interopDefault(import('../pages/campaign-template/index.vue' /* webpackChunkName: "pages/campaign-template/index" */))
const _205a8ca6 = () => interopDefault(import('../pages/form/index.vue' /* webpackChunkName: "pages/form/index" */))
const _509db522 = () => interopDefault(import('../pages/header/index.vue' /* webpackChunkName: "pages/header/index" */))
const _16731260 = () => interopDefault(import('../pages/map/index.vue' /* webpackChunkName: "pages/map/index" */))
const _1b2daebe = () => interopDefault(import('../pages/modal/index.vue' /* webpackChunkName: "pages/modal/index" */))
const _01dcc08e = () => interopDefault(import('../pages/news-post/index.vue' /* webpackChunkName: "pages/news-post/index" */))
const _27db3e9e = () => interopDefault(import('../pages/page/index.vue' /* webpackChunkName: "pages/page/index" */))
const _c587fda8 = () => interopDefault(import('../pages/page-template/index.vue' /* webpackChunkName: "pages/page-template/index" */))
const _16fb49bc = () => interopDefault(import('../pages/preview/index.vue' /* webpackChunkName: "pages/preview/index" */))
const _91208880 = () => interopDefault(import('../pages/scene/index.vue' /* webpackChunkName: "pages/scene/index" */))
const _a80bf5f6 = () => interopDefault(import('../pages/unsubscribe/index.vue' /* webpackChunkName: "pages/unsubscribe/index" */))
const _1196a466 = () => interopDefault(import('../pages/widget/index.vue' /* webpackChunkName: "pages/widget/index" */))
const _5ebaa9bf = () => interopDefault(import('../pages/work-activity/index.vue' /* webpackChunkName: "pages/work-activity/index" */))
const _73186fdc = () => interopDefault(import('../pages/news-post/view/index.vue' /* webpackChunkName: "pages/news-post/view/index" */))
const _08f05e4c = () => interopDefault(import('../pages/work-activity/map/index.vue' /* webpackChunkName: "pages/work-activity/map/index" */))
const _f5bcc56a = () => interopDefault(import('../pages/work-activity/view/index.vue' /* webpackChunkName: "pages/work-activity/view/index" */))
const _224f74ec = () => interopDefault(import('../pages/campaigns/news-post/_id.vue' /* webpackChunkName: "pages/campaigns/news-post/_id" */))
const _6d4cf898 = () => interopDefault(import('../pages/work-activity/map/_id.vue' /* webpackChunkName: "pages/work-activity/map/_id" */))
const _39469b7f = () => interopDefault(import('../pages/campaigns/_id.vue' /* webpackChunkName: "pages/campaigns/_id" */))
const _0e92fd64 = () => interopDefault(import('../pages/contents/_id.vue' /* webpackChunkName: "pages/contents/_id" */))
const _014cb77a = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/campaign-template",
    component: _534af48b,
    name: "campaign-template"
  }, {
    path: "/form",
    component: _205a8ca6,
    name: "form"
  }, {
    path: "/header",
    component: _509db522,
    name: "header"
  }, {
    path: "/map",
    component: _16731260,
    name: "map"
  }, {
    path: "/modal",
    component: _1b2daebe,
    name: "modal"
  }, {
    path: "/news-post",
    component: _01dcc08e,
    name: "news-post"
  }, {
    path: "/page",
    component: _27db3e9e,
    name: "page"
  }, {
    path: "/page-template",
    component: _c587fda8,
    name: "page-template"
  }, {
    path: "/preview",
    component: _16fb49bc,
    name: "preview"
  }, {
    path: "/scene",
    component: _91208880,
    name: "scene"
  }, {
    path: "/unsubscribe",
    component: _a80bf5f6,
    name: "unsubscribe"
  }, {
    path: "/widget",
    component: _1196a466,
    name: "widget"
  }, {
    path: "/work-activity",
    component: _5ebaa9bf,
    name: "work-activity"
  }, {
    path: "/news-post/view",
    component: _73186fdc,
    name: "news-post-view"
  }, {
    path: "/work-activity/map",
    component: _08f05e4c,
    name: "work-activity-map"
  }, {
    path: "/work-activity/view",
    component: _f5bcc56a,
    name: "work-activity-view"
  }, {
    path: "/campaigns/news-post/:id?",
    component: _224f74ec,
    name: "campaigns-news-post-id"
  }, {
    path: "/work-activity/map/:id",
    component: _6d4cf898,
    name: "work-activity-map-id"
  }, {
    path: "/campaigns/:id?",
    component: _39469b7f,
    name: "campaigns-id"
  }, {
    path: "/contents/:id?",
    component: _0e92fd64,
    name: "contents-id"
  }, {
    path: "/",
    component: _014cb77a,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
