<template>
    <div v-if="attrs.media && attrs.media.type" class="media-item-container">
        <Component
            :is="href ? 'a' : 'div'"
            v-if="['image', 'before_after', 'video'].includes(attrs.media.type)"
            :href="href"
            style="width: 100%; max-width: 720px; display: block; border: 0px"
            :target="
                attrs.open_new_tab || attrs.on_click === 'larger_new_tab'
                    ? '_blank'
                    : undefined
            "
            :download="attrs.on_click === 'download'"
            :class="{
                'media-item-full-width': width === 720,
            }"
        >
            <img
                v-if="attrs.media.ref"
                :src="imgURL"
                :style="`
                    width: 100%;
                    max-width: ${width}px;
                    display: block;
                    border: 0px;
                `"
                border="0"
                height="auto"
                :width="width"
                :alt="alt"
                :class="{
                    'media-item-img-full-width': width === 720,
                }"
            />
        </Component>

        <div
            v-else-if="attrs.media && attrs.media.type === '360'"
            :ref="attrs.media.id"
            class="panorama"
        ></div>
    </div>
</template>

<script>
import onClickActions from '~/mixins/onClickActions';

export default {
    name: 'Media',
    mixins: [onClickActions],
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props || {},
        };
    },
    computed: {
        imgURL() {
            let url = `https://${this.$config.API_MEDIA_SERVICE_DOMAIN}/${this.attrs.media.ref}?w=${this.width}`;
            if (this.attrs.media.type === 'video') {
                url = `${url}&p=thumb&mark=play-circle.png&markpos=center&markh=100`;
            }
            return url;
        },
        width() {
            const column =
                this.componentData?.parentData?.name === 'NewsPostColumn';
            return column ? 360 : 720;
        },
        height() {
            return this.attrs.height ?? 480;
        },
        alt() {
            return this.attrs.alt ?? this.attrs.media?.title;
        },
        quality() {
            return this.attrs.quality ?? 70;
        },
    },
};
</script>
