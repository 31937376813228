<template>
    <div
        v-if="loading"
        class="text-center"
        :style="style"
        @click="doClickAction"
    >
        <div class="mb-3">Media Collection Loading...</div>
        <b-spinner
            variant="primary"
            label="Spinner"
            style="width: 150px; height: 150px"
        ></b-spinner>
    </div>
    <div
        v-else-if="error"
        class="text-center"
        :style="style"
        @click="doClickAction"
    >
        Error loading Media Collection
    </div>
    <div v-else class="mediacolleciton" :style="style" @click="doClickAction">
        <div
            v-if="
                attrs.include_media_collection_filter &&
                !componentData.isChildCollection
            "
        >
            FILTER:
            <b-button
                class="button-link btn scroll ca-component ca-buttonlink"
                :style="buttonStyle"
                @click="
                    // eslint-disable-next-line vue/no-mutating-props
                    activeCollection = attrs.media_collection_id
                "
                >{{ title }}</b-button
            >
            <b-button
                v-for="child of children"
                :key="child.id"
                :style="buttonStyle"
                class="mx-2 button-link btn scroll ca-component ca-buttonlink"
                @click="
                    // eslint-disable-next-line vue/no-mutating-props
                    activeCollection = child.id
                "
            >
                {{ child.title }}
            </b-button>
        </div>
        <template
            v-if="
                !(
                    attrs.include_media_collection_filter &&
                    !componentData.isChildCollection &&
                    activeCollection &&
                    attrs.media_collection_id !== activeCollection
                )
            "
        >
            <div v-if="attrs.layout !== 'Accordion'" class="mb-2">
                <h3
                    v-if="
                        attrs.include_media_collection_title &&
                        attrs.layout !== 'Pdf list'
                    "
                    class="ca-heading ca-mediacollection-title"
                >
                    {{ title }}
                </h3>
                <div
                    v-if="attrs.include_media_collection_description"
                    class="ca-paragraph"
                >
                    {{ description }}
                </div>
            </div>
            <div
                v-if="!filteredItems || !filteredItems.length"
                class="text-center"
            >
                No Media Collection items found
            </div>
            <template v-else>
                <b-card
                    v-if="attrs.layout === 'Accordion'"
                    no-body
                    class="ca-component ca-accordionitem card"
                >
                    <b-card-header class="p-0 accordion-item-header" role="tab">
                        <div
                            v-b-toggle="attrs.media_collection_id"
                            class="btn btn-link ca-accordion-btn ca-accordion-card-header-title"
                        >
                            {{ title }}
                        </div>
                    </b-card-header>
                    <b-collapse
                        :id="attrs.media_collection_id"
                        role="tabpanel"
                        :accordion="attrs.media_collection_id"
                    >
                        <b-card-body>
                            <div
                                class="ca-component ca-mediacollection ca-cards cards ca-cards-list"
                            >
                                <MediaCollectionItem
                                    v-for="item of filteredItems"
                                    :key="item.id"
                                    v-bind="{ item, ...attrs }"
                                ></MediaCollectionItem>
                            </div>
                        </b-card-body>
                    </b-collapse>
                </b-card>
                <table
                    v-else-if="attrs.layout === 'Pdf list'"
                    class="table actions-table table-hover pdf-accordion-table ca-pdf-table media-table"
                >
                    <thead class="thead-light">
                        <tr>
                            <th
                                v-if="attrs.include_media_collection_title"
                            ></th>
                            <th>File</th>
                            <th>Title</th>
                            <th>
                                {{
                                    attrs.include_media_file_size ? 'Size' : ''
                                }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <MediaCollectionItem
                            v-for="(item, index) of filteredItems"
                            :key="item.id"
                            v-bind="{
                                item,
                                ...attrs,
                                index,
                                collection_title: title,
                                include_media_collection_title:
                                    attrs.include_media_collection_title,
                            }"
                        ></MediaCollectionItem>
                    </tbody>
                </table>
                <b-carousel
                    v-else-if="attrs.layout === 'Carousel'"
                    :interval="attrs.auto_slide ? attrs.slide_timer : 0"
                    :controls="attrs.pagination"
                    :indicators="attrs.pagination"
                    :fade="attrs.slide_effect === 'fade'"
                    :no-animation="attrs.slide_effect === 'none'"
                    background="#ababab"
                    style="text-shadow: 1px 1px 2px #333"
                    class="ca-component ca-mediacollection"
                >
                    <MediaCollectionItem
                        v-for="item of filteredItems"
                        :key="item.id"
                        v-bind="{ item, ...attrs }"
                    ></MediaCollectionItem>
                </b-carousel>
                <div
                    v-else
                    class="ca-component ca-mediacollection ca-cards cards"
                    :class="{
                        'ca-cards-grid': attrs.layout !== 'List',
                        'ca-cards-list': attrs.layout === 'List',
                    }"
                >
                    <MediaCollectionItem
                        v-for="item of filteredItems"
                        :key="item.id"
                        v-bind="{ item, ...attrs }"
                    ></MediaCollectionItem>
                </div>
            </template>
        </template>
        <MediaCollection
            v-for="child of filterCollections"
            :key="child.id"
            :component-data="{
                ...componentData,
                isChildCollection: true,
                props: {
                    ...attrs,
                    media_collection_id: child.id,
                },
            }"
            :active-collection="activeCollection"
        ></MediaCollection>
        <DragHandle />
        <Adder
            :component-data="componentData.parentData"
            :child-u-u-i-d="componentData.uuid"
        />
    </div>
</template>

<script>
import MediaCollectionItem from './MediaCollectionItem';
import Adder from '~/components/editor/Adder';
import DragHandle from '~/components/editor/DragHandle';
import transformMedia from '@/utils/transformMedia.js';
import componentInlineStyle from '~/utils/componentInlineStyle';
export default {
    name: 'MediaCollection',
    components: {
        MediaCollectionItem,
        Adder,
        DragHandle,
    },
    props: {
        componentData: Object,
        activeCollection: String,
    },
    data() {
        return {
            error: null,
            loading: null,
            attrs: this.componentData.props || {},
            title: null,
            description: null,
            items: [],
            children: [],
        };
    },
    computed: {
        style() {
            return {
                ...componentInlineStyle(this.attrs, this.getColor),
            };
        },
        buttonStyle() {
            return {
                backgroundColor: this.getColor(this.attrs.button_bg_color),
                color: this.getColor(this.attrs.button_text_color),
            };
        },
        filterCollections() {
            const vm = this;
            return vm.children.filter((item) => {
                if (
                    vm.attrs.include_media_collection_filter &&
                    !vm.componentData.isChildCollection &&
                    vm.activeCollection &&
                    item.id !== vm.activeCollection
                ) {
                    return false;
                }
                return true;
            });
        },
        filteredItems() {
            const vm = this;
            const filtered = this.items.filter((item) => {
                if (!item?.ref) {
                    return false;
                }
                return (
                    item &&
                    // check if show only public
                    !(
                        vm.attrs.media_to_show &&
                        vm.attrs.media_to_show ===
                            'public_collection_media_only' &&
                        item.public === false
                    )
                );
            });
            if (this.attrs.items_to_show !== -1) {
                return filtered.slice(0, this.attrs.items_to_show);
            }
            return filtered;
        },
    },
    watch: {
        async 'componentData.props.media_collection_id'() {
            await this.fetchData();
        },
        async 'componentData.props.items_to_show'() {
            await this.fetchData();
        },
        async 'componentData.props.include_child_collections'() {
            await this.fetchData();
        },
    },
    async mounted() {
        await this.fetchData();
    },
    methods: {
        async fetchData() {
            const vm = this;
            this.error = false;
            this.loading = true;
            try {
                const id = this.attrs.media_collection_id;
                let media = [];
                if (id) {
                    const { title, description, ...response } = (
                        await this.$axios.$get(`sets/${id}`, {
                            params: {
                                include: this.attrs.include_child_collections
                                    ? 'children'
                                    : undefined,
                            },
                        })
                    )?.data;
                    this.title = title;
                    this.description = description;
                    const children = response?.children?.data ?? [];
                    this.children = children;
                    media = response?.media?.data ?? [];
                } else {
                    const response = await this.$axios.$get('media', {
                        params: {
                            sort: '-created_at',
                            'filter[type]': 'image,video,before_after,document',
                            'filter[set]': 'root',
                            'filter[project_id]':
                                this.$store.state.api.projectID,
                            limit: this.attrs.items_to_show,
                        },
                    });
                    this.title = '(root)';
                    media = response?.data ?? [];
                }
                this.items = await Promise.all(
                    media.map((data) => transformMedia(data, vm.$axios)),
                );
            } catch (e) {
                console.error(e);
                this.error = true;
            } finally {
                this.loading = false;
            }
        },
        onClick() {
            this.openEditor(this.componentData.uuid);
        },
    },
};
</script>

<style lang="scss">
.ca-cards-grid {
    display: grid;
}
.ca-cards-list {
    display: block;
}

.mediacolleciton {
    &:hover {
        .drag-handle,
        .adder {
            opacity: 1;
        }
    }
}
</style>
