<template>
    <td
        align="center"
        valign="top"
        style="width: 300px"
        class="news-post-column-container"
    >
        <table border="0" cellpadding="0" cellspacing="0" style="width: 300px">
            <tr>
                <td>
                    <slot></slot>
                </td>
            </tr>
        </table>
    </td>
</template>

<script>
export default {
    name: 'NewsPostColumn',
};
</script>

<style>
.preview.xs .news-post-column-container {
    display: block !important;
    width: 100% !important;
}
@media only screen and (max-width: 480px) {
    .news-post-column-container {
        display: block !important;
        width: 100% !important;
    }
}
</style>
