import workActivityTopics from '../data/workActivityTopics';

export default {
    accordion_size: {
        label: 'Title size',
        default: 'lg',
        options: [
            {
                text: 'Small',
                value: 'sm',
            },
            {
                text: 'Large',
                value: 'lg',
            },
        ],
        type: 'Radio',
    },
    after_img: {
        label: 'After image',
        validations: ['required', 'url'],
        type: 'String',
    },
    align: {
        default: null,
        label: 'Text align',
        options: [
            {
                text: 'Left',
                value: 'left',
            },
            {
                text: 'Center',
                value: 'center',
            },
            {
                text: 'Right',
                value: 'right',
            },
            {
                text: 'Inherit',
                value: null,
            },
        ],
        type: 'Radio',
    },
    align_content: {
        default: 'center',
        label: 'Align content',
        options: [
            {
                text: 'Left',
                value: 'start',
            },
            {
                text: 'Center',
                value: 'center',
            },
            {
                text: 'Right',
                value: 'end',
            },
        ],
        type: 'Radio',
    },
    arrowheads_width: {
        label: 'Arrowhead size',
        default: 6,
        type: 'Range',
        config: {
            step: 1,
            min: 1,
            max: 12,
        },
    },
    autoplay: {
        default: false,
        label: 'Autoplay',
        type: 'Boolean',
    },
    auto_slide: {
        label: 'Auto slide',
        default: false,
        type: 'Boolean',
    },
    bearing: {
        label: 'Bearing',
        validations: ['numeric'],
        type: 'Number',
    },
    before_img: {
        label: 'Before image',
        validations: ['required', 'url'],
        type: 'String',
    },
    bg_color: {
        label: 'Background color',
        validations: [],
        type: 'Colorpicker',
    },
    bg_pos: {
        default: 'center',
        label: 'Background position',
        options: ['bottom', 'center', 'top'],
        validations: ['required'],
        type: 'Radio',
    },
    bg_size: {
        default: 'md',
        label: 'Background size',
        // default: 'cover',
        options: ['contain', 'cover'],
        validations: ['required'],
        type: 'Radio',
    },
    bg_src: {
        label: 'Background image',
        placeholder: 'URL to image source',
        validations: ['required'],
        type: 'String',
    },
    bg_type: {
        label: 'Background type',
        default: 'color',
        options: ['color', 'media'],
        type: 'Radio',
    },
    block: {
        label: 'Full-width',
        default: false,
        validations: ['required'],
        config: {
            tooltip: 'Full-width only applies to stacked buttons.',
        },
        type: 'Boolean',
    },
    border_color: {
        label: 'Border color',
        validations: [],
        type: 'Colorpicker',
    },
    button_align: {
        default: 'row',
        label: 'Button alignment',
        options: ['stacked', 'row'],
        type: 'Radio',
    },
    byline: {
        label: 'Byline',
        validations: [],
        default: 'Byline',
        type: 'String',
        config: {},
    },
    campaign_template_id: {
        label: 'Campaign template',
        default: null,
        contentType: 'campaign_template',
        config: {
            multiple: false,
        },
        type: 'ApiContentSelector',
    },
    card_size: {
        default: 'lg',
        label: 'Card size',
        options: [
            {
                text: 'Small',
                value: 'sm',
            },
            {
                text: 'Medium',
                value: 'md',
            },
            {
                text: 'Large',
                value: 'lg',
            },
        ],
        validations: ['required'],
        type: 'Radio',
    },
    caption: {
        label: 'Caption',
        validations: [],
        type: 'Textarea',
    },
    category: {
        label: 'Category',
        type: 'Select',
        options: ['Default', 'Traffic', 'Air Quality', 'Noise', 'Construction'],
        default: 'Default',
    },
    center: {
        label: 'Center',
        placeholder: '[lat, lon]',
        type: 'String',
        validations: ['required'],
    },
    close_on_esc: {
        label: 'Close on escape',
        default: true,
        type: 'Boolean',
    },
    color: {
        label: 'Color',
        default: 'primary',
        validations: [],
        type: 'Colorpicker',
    },
    col_span: {
        default: '3',
        options: ['2', '3', '4'],
        label: 'Number of Column',
        type: 'Radio',
    },
    content: {
        label: 'Content',
        validations: ['required'],
        type: 'Html',
    },
    content_pos: {
        default: 'center',
        label: 'Content position',
        options: ['bottom', 'center', 'top'],
        type: 'Radio',
    },
    content_source: {
        default: 'Editor',
        label: 'Content source',
        options: ['Editor', 'External'],
        type: 'Radio',
    },
    css: {
        label: 'Custom css',
        hidden: true,
        config: {
            lang: 'css',
        },
        type: 'Code',
    },
    dashed: {
        label: 'Dashed',
        validations: ['required'],
        type: 'Boolean',
    },
    date: {
        label: 'Date',
        validations: ['required'],
        type: 'Datepicker',
        width: '50%',
    },
    date_end: {
        label: 'Works date end',
        type: 'Datepicker',
        width: '50%',
        config: {
            minDate: null,
            tooltip:
                'The date this Work Activity finishes. If there is no set end date you can leave this field blank, and it will display as “ongoing” to stakeholders.',
        },
        validations: ['after:date_start'],
    },
    date_start: {
        label: 'Works date start',
        type: 'Datepicker',
        width: '50%',
        validations: ['required'],
        config: {
            tooltip: 'The date this Work Activity commences work.',
        },
    },
    description: {
        label: 'Description',
        validations: [],
        type: 'Textarea',
    },
    display_popup: {
        label: 'Display popup',
        options: ['Never', 'Hover', 'Click'],
        default: 'Click',
        type: 'Radio',
    },
    show_popup_editing: {
        label: 'Show popup while editing',
        options: [
            {
                text: 'Show',
                value: true,
            },
            {
                text: 'Hide',
                value: false,
            },
        ],
        default: false,
        type: 'Radio',
    },
    dismissible: {
        default: true,
        label: 'Is dismissible?',
        type: 'Boolean',
    },
    external_file: {
        label: 'Path to file',
        default: '',
        type: 'String',
    },
    facebook: {
        label: 'Facebook url',
        default: 'https://facebook.com',
        type: 'String',
    },
    instagram: {
        label: 'Instagram url',
        default: 'https://instagram.com',
        type: 'String',
    },
    twitter: {
        label: 'Twitter url',
        default: 'https://twitter.com',
        type: 'String',
    },
    youtube: {
        label: 'YouTube url',
        default: 'https://youtube.com',
        type: 'String',
    },
    linkedin: {
        label: 'LinkedIn url',
        default: 'https://linkedin.com',
        type: 'String',
    },
    fade: {
        label: 'Fade transition',
        default: true,
        type: 'Boolean',
    },
    fill_opacity: {
        label: 'Fill opacity',
        default: '0.8',
        type: 'Range',
        config: {
            step: '0.1',
            min: '0',
            max: '1',
        },
    },
    content_status: {
        label: 'Work activities to show',
        type: 'Select',
        Default: 'Active',
        options: [
            'Active',
            'Upcoming',
            'Upcoming and active',
            'Previous',
            'Date range',
            'Custom',
        ],
    },
    filter_by_status: {
        label: 'Filter by status',
        type: 'Select',
        Default: 'All',
        options: ['All', 'Published only'],
    },
    date_range: {
        label: 'Date range',
        type: 'DateRange',
    },
    flex_grow: {
        default: '4',
        label: 'Column size',
        options: [
            {
                text: 'Fit',
                value: '0',
            },
            {
                text: 'Small',
                value: '2',
            },
            {
                text: 'Medium',
                value: '4',
            },
            {
                text: 'Large',
                value: '8',
            },
        ],
        validations: [],
        type: 'Radio',
    },
    font_size: {
        default: '1rem',
        label: 'Font size',
        validations: ['required'],
        type: 'String',
    },
    font_weight: {
        default: '400',
        label: 'Font weight',
        options: [
            {
                text: 'Regular',
                value: '400',
            },
            {
                text: 'Bold',
                value: '700',
            },
        ],
        type: 'Radio',
    },
    form_conditions: {
        label: 'Form conditions',
        default: [],
        type: 'FormConditions',
    },
    form_options: {
        label: 'Form options',
        default: [],
        type: 'FormOptions',
    },
    show_footer: {
        label: 'Show footer',
        default: false,
        type: 'Boolean',
    },
    show_arrow_start: {
        default: true,
        label: 'Show arrow start',
        type: 'Boolean',
    },
    show_arrow_end: {
        default: true,
        label: 'Show arrow end',
        type: 'Boolean',
    },
    show_arrowheads: {
        default: 'None',
        label: 'Show arrowheads',
        options: ['None', 'Start', 'End', 'Both'],
        type: 'Radio',
    },
    show_author: {
        default: true,
        label: 'Show author',
        type: 'Boolean',
    },
    show_date: {
        default: true,
        label: 'Show date',
        type: 'Boolean',
    },
    show_excerpt: {
        default: true,
        label: 'Show excerpt',
        type: 'Boolean',
    },
    footer_text: {
        label: 'Footer text',
        default: '',
        type: 'String',
    },
    footer_url: {
        label: 'Footer link',
        default: '',
        type: 'String',
    },
    footer: {
        label: 'Page footer',
        default: null,
        contentType: 'footer',
        config: {
            multiple: false,
        },
        type: 'ApiContentSelector',
    },
    header: {
        label: 'Page header',
        default: null,
        contentType: 'header',
        config: {
            multiple: false,
        },
        type: 'ApiContentSelector',
    },
    content_id: {
        label: 'Widget',
        default: null,
        contentType: 'widget',
        config: {
            multiple: false,
        },
        type: 'ApiContentSelector',
    },
    header_color: {
        label: 'Header color',
        default: 'dark',
        type: 'Colorpicker',
    },
    heading_size: {
        label: 'Heading size',
        default: 2,
        options: [1, 2, 3, 4, 5, 6],
        validations: ['required'],
        type: 'Radio',
    },
    heading: {
        label: 'Heading',
        validations: [],
        default: 'Heading text',
        type: 'String',
        config: {},
    },
    height: {
        label: 'Height',
        options: [
            {
                text: 'Auto',
                value: 'auto',
            },
            {
                text: 'Medium',
                value: '50vh',
            },
            {
                text: 'Large',
                value: '90vh',
            },
            {
                text: 'Full',
                value: '100vh',
            },
        ],
        default: 'auto',
        type: 'Radio',
    },
    hotspots: {
        default: [],
        label: 'Hotspots',
        type: 'Array',
        hidden: true,
    },
    hotspot_size: {
        default: 'md',
        label: 'Hotspot size',
        options: [
            {
                text: 'Extra Small',
                value: 'xs',
            },
            {
                text: 'Small',
                value: 'sm',
            },
            {
                text: 'Medium',
                value: 'md',
            },
            {
                text: 'Large',
                value: 'lg',
            },
        ],
        validations: [],
        type: 'Radio',
    },
    href: {
        label: 'URL',
        default: null,
        type: 'String',
    },
    icon: {
        label: 'Icon',
        validations: [],
        type: 'Iconpicker',
        default: 'search-plus',
    },
    icon_color: {
        label: 'Icon background color',
        default: 'dark',
        validations: [],
        type: 'Colorpicker',
    },
    icon_fav: {
        label: 'Favourite icons',
        default: null,
        type: 'FavouriteIconpicker',
    },
    icon_img: {
        label: 'Icon image',
        validations: [],
        type: 'Customiconpicker',
    },
    icon_pos: {
        default: 'left',
        label: 'Icon position',
        options: ['left', 'right'],
        validations: [],
        type: 'Radio',
    },
    icon_size: {
        label: 'Icon size',
        options: [
            {
                text: 'Default',
                value: '1x',
            },
            {
                text: 'Large',
                value: 'lg',
            },
            {
                text: '2x',
                value: '2x',
            },
            {
                text: '3x',
                value: '3x',
            },
            {
                text: '4x',
                value: '4x',
            },
            {
                text: '5x',
                value: '5x',
            },
        ],
        default: '1x',
        type: 'Radio',
    },
    icon_text: {
        label: 'Icon text',
        type: 'String',
    },
    icon_inner_color: {
        label: 'Icon inner color',
        default: 'light',
        options: ['light', 'dark'],
        type: 'Radio',
    },
    icon_type: {
        label: 'Icon type',
        options: [
            {
                text: 'Favourite',
                value: 'favourite',
            },
            {
                text: 'Icon',
                value: 'icon',
            },
            {
                text: 'Image',
                value: 'image',
            },
            {
                text: 'Text',
                value: 'text',
            },
            {
                text: 'None',
                value: 'none',
            },
        ],
        validations: [],
        default: 'icon',
        type: 'Radio',
    },
    icon_prefix: {
        label: 'Icon prefix',
        options: [
            { val: 'far', label: 'Regular' },
            { val: 'fal', label: 'Light' },
            { val: 'fas', label: 'Solid' },
        ],
    },
    id: {
        label: 'ID',
        validations: ['required'],
        type: 'String',
    },
    image_coords: {
        label: 'Image coords',
        type: 'String',
    },
    image_width: {
        label: 'Width',
        type: 'Number',
        default: 1080,
        config: {
            min: 0,
            // https://www.w3schools.com/tags/att_img_width.asp
            tooltip:
                'The width attribute specifies the width of an image, in pixels. The space required for the image is reserved when the page is loaded.',
        },
    },
    image_height: {
        label: 'Height',
        type: 'Number',
        default: 720,
        config: {
            min: 0,
            // https://www.w3schools.com/tags/att_img_height.asp
            tooltip:
                'The height attribute specifies the height of an image, in pixels. The space required for the image is reserved when the page is loaded.',
        },
    },
    image_alt: {
        label: 'Title',
        type: 'String',
        config: {
            // https://www.w3schools.com/tags/att_img_alt.asp
            tooltip:
                'Alternative information for an image if a user for some reason cannot view it (because of slow connection, an error, or if the user uses a screen reader). Defaults to image file name.',
        },
    },
    image_quality: {
        label: 'Quality',
        type: 'Range',
        default: 70,
        config: {
            // https://glide.thephpleague.com/2.0/api/encode/#quality-q
            tooltip:
                'Defines the quality of the image. Use values between 0 and 100. Defaults to 70.',
            step: 5,
            min: 0,
            max: 100,
        },
    },
    includes_weekends: {
        label: 'Includes weekends',
        type: 'Boolean',
        default: false,
        validations: [],
    },
    input_type: {
        label: 'Input type',
        default: 'text',
        options: ['text', 'date', 'datetime', 'time', 'range', 'color'],
    },
    is_visible: {
        label: 'Is visible',
        default: true,
        type: 'Boolean',
    },
    js: {
        label: 'Custom javascript',
        hidden: true,
        config: {
            lang: 'js',
        },
        type: 'Code',
    },
    justify_content: {
        default: 'center',
        label: 'Alignment',
        options: [
            {
                text: 'Left',
                value: 'start',
            },
            {
                text: 'Center',
                value: 'center',
            },
            {
                text: 'Right',
                value: 'end',
            },
            {
                text: 'Stretch',
                value: 'stretch',
            },
        ],
        type: 'Radio',
    },
    label: {
        label: 'Label',
        validations: ['required'],
        type: 'String',
    },
    label_display: {
        label: 'Display label',
        default: 'always',
        options: ['always', 'never', 'hover'],
        type: 'Radio',
    },
    label_pos: {
        default: 'right',
        label: 'Label pos.',
        options: ['bottom', 'left', 'right', 'top'],
        type: 'Radio',
        validations: [],
    },
    lat: {
        label: 'Latitude',
        validations: ['required'],
        type: 'String',
    },
    lat2: {
        label: 'Lat2',
        type: 'String',
    },
    label_group: {
        placeholder: 'Layer group',
        type: 'String',
        validations: ['required'],
    },
    layer_src: {
        label: 'Layer source',
        type: 'String',
        validations: ['required'],
    },
    layout: {
        default: 'list',
        label: 'Layout',
        options: ['grid', 'list'],
        validations: ['required'],
        type: 'String',
    },
    legend: {
        default: false,
        label: 'Show in legend',
        validations: ['required'],
        type: 'Boolean',
    },
    show_legend: {
        default: true,
        label: 'Show legend',
        validations: ['required'],
        hidden: true,
        type: 'Boolean',
    },
    line_height: {
        default: '1.125rem',
        label: 'Line height',
        validations: ['required'],
        type: 'String',
    },
    line_cap: {
        label: 'Line cap',
        default: 'butt',
        options: [
            {
                text: 'None',
                value: 'butt',
            },
            {
                text: 'Round',
                value: 'round',
            },
            {
                text: 'Square',
                value: 'square',
            },
        ],
        type: 'Radio',
    },
    line_width: {
        label: 'Line width',
        default: 5,
        type: 'Range',
        config: {
            step: 1,
            min: 1,
            max: 10,
        },
    },
    line_dasharray: {
        label: 'Line dash',
        default: [1],
        type: 'Radio',
        options: [
            {
                text: 'None',
                value: [1],
            },
            {
                text: 'Short',
                value: [0.5, 1],
            },
            {
                text: 'Even',
                value: [1, 1],
            },
            {
                text: 'Long',
                value: [2, 0.5],
            },
            {
                text: 'Mixed',
                value: [2, 0.5, 0.5, 0.5],
            },
        ],
    },
    location: {
        label: 'Location',
        type: 'StringMultiselect',
        width: '50%',
        default: [],
        options: ['Iron Cove', 'Rozelle', 'Leichhardt', 'Lilyfield'],
        validations: [],
        config: {
            tooltip:
                'Optional. Used to filter and group Work Activities in the frontend based on a common location value. Locations can be set in this project’s advanced settings.',
        },
    },
    location_bias: {
        label: 'Bias to project area',
        default: false,
        type: 'Boolean',
    },
    lon: {
        label: 'Longitude',
        validations: ['required'],
        type: 'String',
    },
    lon2: {
        label: 'Lon2',
        type: 'String',
    },
    map_id: {
        label: 'Map ID',
        default: [],
        contentType: 'map',
        config: {
            multiple: false,
        },
        type: 'ApiContentSelector',
    },
    map_style_url: {
        label: 'Style url',
        default: '',
        type: 'String',
    },
    marker_id: {
        placeholder: 'Marker ID',
        type: 'String',
        validations: ['required'],
    },
    max_bounds: {
        label: 'Max bounds',
        placeholder: '[[lat1, lon1], [lat2, lon2]]',
        type: 'String',
        validations: ['required'],
    },
    maxBoundsLat1: {
        label: 'Max bounds 1: Lat',
        validations: ['required'],
        type: 'String',
    },
    maxBoundsLat2: {
        label: 'Max bounds 2: Lat',
        validations: ['required'],
        type: 'String',
    },
    maxBoundsLon1: {
        label: 'Max bounds 1: Lon',
        validations: ['required'],
        type: 'String',
    },
    maxBoundsLon2: {
        label: 'Max bounds 2: Lon',
        validations: ['required'],
        type: 'String',
    },
    max_zoom: {
        label: 'Max zoom',
        validations: ['required'],
        config: {
            min: '0',
            max: '24',
        },
        width: '50%',
        type: 'Number',
    },
    media: {
        label: 'Media',
        type: 'Mediapicker',
    },
    message: {
        label: 'Message',
        default: '<p>Click to enter text</p>',
        type: 'Html',
        hidden: 'always',
    },
    meta_description: {
        label: 'SEO description',
        type: 'Textarea',
    },
    min_zoom: {
        label: 'Min zoom',
        validations: ['required'],
        config: {
            min: '0',
            max: '24',
        },
        width: '50%',
        type: 'Number',
    },
    modal_id: {
        placeholder: 'Select a popup',
        type: 'ApiContentSelector',
        contentType: 'modal',
        default: null,
        config: {
            multiple: false,
        },
    },
    modal_size: {
        type: 'Radio',
        default: 'md',
        options: [
            {
                text: 'Default',
                value: 'md',
            },
            {
                text: 'Small',
                value: 'sm',
            },
            {
                text: 'Large',
                value: 'lg',
            },
            {
                text: 'XL',
                value: 'xl',
            },
        ],
        config: {},
    },
    work_activity_topics: {
        label: 'Topics',
        type: 'Tags',
        default: [],
        width: '50%',
        options: workActivityTopics,
        config: {
            tooltip:
                'Optional. Used to filter and group Work Activities in the frontend based on a common topics. Topics can be set in this project’s advanced settings.',
        },
    },
    feature_group: {
        label: 'Layer group',
        type: 'String',
    },
    on_click: {
        label: 'On click',
        options: [
            {
                label: 'Go to another page',
                val: 'page',
            },
            {
                label: 'Go to a URL',
                val: 'url',
            },
            {
                label: 'Open a media item in a popup',
                val: 'media',
            },
            {
                label: 'Open a form in a popup',
                val: 'form',
            },
            {
                label: 'Open a custom popup',
                val: 'modal',
            },
            {
                label: 'Download a media item',
                val: 'download',
            },
            {
                label: 'Default/no action',
                val: null,
            },
        ],
        default: null,
        type: 'Select',
    },
    click_modal: {
        label: 'Select a popup',
        default: null,
        contentType: 'modal',
        config: {
            multiple: false,
        },
        type: 'ApiContentSelector',
    },
    open_new_tab: {
        label: 'Open url in new tab',
        type: 'Boolean',
        default: true,
    },
    click_download: {
        label: 'Download a file',
        type: 'Mediapicker',
    },
    click_media: {
        label: 'Media',
        type: 'Mediapicker',
    },
    logo: {
        label: 'Logo',
        config: {
            tooltip: 'This image will always be capped at 50px height',
        },
        type: 'Mediapicker',
    },
    opacity: {
        label: 'Opacity',
        default: '1',
        type: 'Range',
        config: {
            step: '0.1',
            min: '0',
            max: '1',
        },
    },
    overlay: {
        default: true,
        label: 'Overlay',
        type: 'Boolean',
    },
    pagination: {
        label: 'Pagination',
        default: true,
        type: 'Boolean',
    },
    page: {
        label: 'Page',
        options: [
            {
                text: 'Home',
                value: '/',
            },
            {
                text: 'Gallery',
                value: '/gallery',
            },
            {
                text: 'Map',
                value: '/map',
            },
        ],
        type: 'Search',
    },
    page_template_id: {
        label: 'Page template ID',
        default: null,
        contentType: 'page_template',
        config: {
            multiple: false,
        },
        type: 'ApiContentSelector',
    },
    parent_id: {
        label: 'Parent page',
        placeholder: 'Select a popup',
        type: 'ApiContentSelector',
        contentType: 'page',
        default: null,
        config: {
            multiple: false,
        },
    },
    pitch: {
        label: 'Pitch',
        validations: ['required'],
        type: 'Number',
    },
    yaw: {
        label: 'Yaw',
        validations: ['required'],
        type: 'Number',
    },
    placeholder: {
        label: 'Placeholder',
        default: '',
        type: 'String',
    },
    play_icon: {
        default: false,
        label: 'Show play icon',
        validations: ['required'],
        type: 'Boolean',
    },
    preview_url: {
        label: 'Preview URL',
        default: '',
        hidden: true,
        type: 'String',
    },
    publish_at: {
        label: 'Publish at',
        type: 'Datepicker',
        width: '50%',
        hidden: true,
        validations: ['required'],
    },
    pulse: {
        label: 'Pulse',
        type: 'Boolean',
    },
    max_range: {
        label: 'Max range',
        default: 10,
        type: 'Number',
    },
    min_range: {
        label: 'Min range',
        default: 0,
        type: 'Number',
    },
    range_step: {
        label: 'Step',
        default: 1,
        type: 'Number',
    },
    ref: {
        label: 'Reference #',
        width: '50%',
        type: 'String',
        config: {
            tooltip:
                'Often used as an official ID number for referencing this Work Activity internally and/or externally',
        },
    },
    regions: {
        label: 'Regions',
        default: 'Full',
        options: ['Full', 'Suburb', 'Postcode'],
        type: 'Radio',
    },
    required: {
        label: 'Required',
        default: false,
        type: 'Boolean',
    },
    rounded: {
        label: 'Rounded',
        validations: ['required'],
        type: 'Boolean',
    },
    active_from: {
        label: 'Active from',
        type: 'Datepicker',
    },
    active_to: {
        label: 'Active to',
        type: 'Datepicker',
    },
    scene_id: {
        label: 'Scene ID',
        config: {
            multiple: false,
        },
        default: null,
        contentType: 'scene', // doesn't exist in api yet
        type: 'ApiContentSelector',
    },
    show_campaign_btns: {
        default: true,
        label: 'Show campaign buttons',
        type: 'Boolean',
    },
    show_campaign_title: {
        default: true,
        label: 'Show campaign title',
        type: 'Boolean',
    },
    show_number: {
        type: 'Radio',
        default: '3',
        options: ['3', '6', '9', 'all', 'custom'],
        config: {},
    },
    show_close: {
        label: 'Show close button',
        default: true,
        type: 'Boolean',
    },
    show_icon: {
        default: false,
        label: 'Show icon',
        type: 'Boolean',
    },
    sidebar_id: {
        label: 'Sidebar',
        type: 'String',
    },
    size: {
        default: 'md',
        label: 'Button size',
        options: [
            {
                text: 'Small',
                value: 'sm',
            },
            {
                text: 'Medium',
                value: 'md',
            },
            {
                text: 'Large',
                value: 'lg',
            },
        ],
        validations: [],
        type: 'Radio',
    },
    slot: {
        default: 'left',
        label: 'Slot',
        options: [
            {
                text: 'Left',
                value: 'left',
            },
            {
                text: 'Center',
                value: 'center',
            },
            {
                text: 'Right',
                value: 'right',
            },
        ],
        type: 'Radio',
    },
    slug: {
        label: 'Slug',
        config: {
            tooltipIcon: 'question-circle',
            tooltip: 'Edit the title to generate the slug',
        },
        type: 'Slug',
        validations: ['maxLength:191'],
    },
    src: {
        label: 'Image',
        validations: ['required'],
        type: 'Mediapicker',
    },
    slide_effect: {
        label: 'Animation',
        default: 'slide',
        options: ['slide', 'fade', 'none'],
        type: 'Radio',
    },
    slide_timer: {
        label: 'Slide timer',
        default: 5000,
        options: [
            {
                text: '2s',
                value: 1000,
            },
            {
                text: '5s',
                value: 5000,
            },
            {
                text: '10s',
                value: 10000,
            },
            {
                text: '15s',
                value: 15000,
            },
        ],
        type: 'Radio',
    },
    status: {
        label: 'Status',
        options: ['public', 'private'],
        default: 'private',
        type: 'Radio',
    },
    style: {
        label: 'Map style',
        validations: ['required'],
        type: 'String',
    },
    sticky: {
        label: 'Sticky',
        default: true,
        type: 'Boolean',
    },
    text: {
        default: 'dark',
        label: 'Font color',
        options: ['light', 'dark'],
        type: 'Radio',
    },
    text_shadow: {
        default: false,
        label: 'Text shadow',
        validations: ['required'],
        type: 'Boolean',
    },
    thickness: {
        label: 'Thickness',
        default: '2',
        type: 'Range',
        config: {
            step: '1',
            min: '1',
            max: '10',
        },
    },
    time_desc: {
        label: 'Works time periods',
        default: [],
        type: 'TimePeriods',
        config: {
            tooltip:
                'If your work is carried out on specific days between specific hours, set that information here.',
        },
    },
    title: {
        label: 'Title',
        validations: ['required', 'unique_slug'],
        default: 'Title',
        type: 'String',
        config: {},
        icon: 'address-card',
    },
    inline: {
        label: 'Inline',
        type: 'Boolean',
        default: true,
    },
    inline_title: {
        label: 'Title',
        type: 'Html',
        default: 'Click to edit text',
        inline: true,
    },
    // inline: {
    //     label: 'Inline',
    //     type: 'Boolean',
    //     default: true,
    // },
    input_width: {
        label: 'Width',
        type: 'Radio',
        options: [
            {
                text: '1/4',
                value: 'quarter',
            },
            {
                text: '1/3',
                value: 'third',
            },
            {
                text: '1/2',
                value: 'half',
            },
            {
                text: '2/3',
                value: 'two-thirds',
            },
            {
                text: '3/4',
                value: 'three-quarters',
            },
            {
                text: 'Full',
                value: 'full',
            },
        ],
        default: 'Full',
    },
    toggle_menu_text: {
        label: 'Show hamburger menu',
        default: 'lg',
        type: 'ResponsivePicker',
    },
    type: {
        label: 'Type',
        validations: ['required'],
        type: 'String',
    },
    unpublish_at: {
        label: 'Unpublish at',
        type: 'Datepicker',
        width: '50%',
        hidden: true,
        validations: ['required'],
    },
    thumbnail: {
        label: 'Thumbnail',
        hidden: true,
        type: 'Mediapicker',
    },
    thumb_as_hero: {
        label: 'Use thumbnail as hero',
        default: true,
        options: [
            {
                text: 'Yes',
                value: true,
            },
            {
                text: 'Custom hero',
                value: 'custom',
            },
            {
                text: 'No',
                value: false,
            },
        ],
        type: 'Radio',
    },
    hero: {
        label: 'Hero',
        type: 'Mediapicker',
    },
    weight: {
        default: 'fas',
        label: 'Font weight',
        options: ['far', 'fal', 'fas'],
        type: 'Radio',
    },
    width: {
        default: 'container',
        label: 'Width',
        options: [
            {
                text: 'Fit to content',
                value: 'container',
            },
            {
                text: 'Full-width',
                value: 'fluid',
            },
        ],
        validations: [],
        type: 'Radio',
    },
    vertically_center: {
        default: true,
        label: 'Vertically center',
        type: 'Boolean',
    },
    work_activity_ids: {
        label: 'Custom',
        default: [],
        contentType: 'work_activity',
        config: {
            multiple: true,
        },
        type: 'ApiContentSelector',
    },
    zoom: {
        label: 'Zoom',
        validations: ['required'],
        type: 'Number',
    },
    x: {
        label: 'Hotizontal %',
        type: 'Range',
        width: '50%',
        default: 50,
        config: {
            step: 1,
            min: 1,
            max: 99,
        },
    },
    y: {
        label: 'Vertical %',
        type: 'Range',
        width: '50%',
        default: 50,
        config: {
            step: 1,
            min: 1,
            max: 99,
        },
    },
    percent: {
        label: 'Percent',
        type: 'Range',
        default: 50,
        config: {
            step: 1,
            min: 0,
            max: 100,
        },
    },
    video_display: {
        label: 'Video display',
        type: 'Radio',
        default: 'thumbnail',
        config: {
            tooltip:
                'Choose how the video will be displayed: Thumbnail will show a static image; Embed will include playback controls; Loop will auto-play the video and continuously loop. - Please note: looping the video will mute the audio, if sound is needed, please select embed option.',
        },
        options: [
            {
                text: 'Thumbnail',
                value: 'thumbnail',
                tooltip: 'Can be clicked to view the video in a larger modal.',
            },
            {
                text: 'Embed',
                value: 'embed',
                tooltip: 'Played back at the end-user’s discretion.',
            },
            {
                text: 'Loop',
                value: 'background',
                tooltip:
                    'Plays in the background (muted, looped, without controls).',
            },
        ],
    },
    include_child_projects: {
        label: 'Include child projects',
        type: 'Boolean',
        default: null,
    },
};
