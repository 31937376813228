var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table',{staticStyle:{"width":"100%"},attrs:{"width":"100%"}},[_c('tbody',{staticStyle:{"width":"100%"},attrs:{"width":"100%"}},[_c('tr',{staticStyle:{"width":"100%"},attrs:{"width":"100%"}},[_c('td',{style:({
                    backgroundColor: _vm.getColor('brand_secondary'),
                    borderRadius: '4px',
                    height: '32px',
                    textAlign: 'center',
                    width: '100%',
                }),attrs:{"width":"100%"}},[_c('a',{style:({
                        color: '#fff',
                        textAlign: 'center',
                        textDecoration: 'none',
                        verticalAlign: 'middle',
                        lineHeight: '32px',
                        fontFamily: _vm.projectConfig.fontFamily,
                        width: '100%',
                    }),attrs:{"href":_vm.attrs.src,"target":"_blank","rel":"noopener"}},[_vm._v("\n                    Play Video\n                ")])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }