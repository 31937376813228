<template>
    <div ref="workActivity" class="work-activity">
        <head v-html="css" />

        <div class="heading-container p-3" :style="style">
            <div class="heading d-flex">
                <h5
                    :style="{ fontFamily: projectConfig.fontFamily }"
                    class="my-2"
                >
                    <strong>{{ attrs.title }}</strong>
                </h5>
            </div>
            <div v-show="attrs.show_description && attrs.description">
                {{ attrs.description }}
            </div>
            <h6
                v-show="attrs.ref"
                :style="{ fontFamily: projectConfig.fontFamily }"
                class="mb-2"
            >
                <em>Notification No. {{ attrs.ref }}</em>
            </h6>
        </div>

        <div class="content p-3">
            <Thumbnail
                v-if="attrs.thumbnail"
                class="mb-3"
                :component-data="{
                    props: {
                        media: attrs.thumbnail,
                    },
                }"
            />

            <Accordion :editable="false">
                <AccordionItem
                    :visible="true"
                    :editable="false"
                    :component-data="summaryData"
                >
                    <p :style="{ fontFamily: projectConfig.fontFamily }">
                        <font-awesome-icon
                            :icon="['fal', 'map-marker-alt']"
                            class="mr-2"
                        />
                        {{ attrs.location }}
                    </p>
                    <p :style="{ fontFamily: projectConfig.fontFamily }">
                        <font-awesome-icon
                            :icon="['fal', 'calendar']"
                            class="mr-2"
                        />
                        {{
                            $moment(new Date(attrs.date_start))
                                .tz('UTC')
                                .format($config.WORK_ACTIVITY_DATE_FORMAT)
                        }}
                        -
                        {{
                            attrs.date_end
                                ? $moment(new Date(attrs.date_end))
                                      .tz('UTC')
                                      .format($config.WORK_ACTIVITY_DATE_FORMAT)
                                : 'ongoing'
                        }}
                    </p>
                    <template v-if="attrs.time_desc">
                        <p
                            v-for="tp in attrs.time_desc.filter((tp) => tp)"
                            :key="tp.uuid"
                            :style="{ fontFamily: projectConfig.fontFamily }"
                        >
                            <font-awesome-icon
                                :icon="['fal', 'clock']"
                                class="mr-2"
                            />
                            {{ workActivityTimePeriodText(tp) }}
                        </p>
                    </template>
                    <p
                        v-for="topic in attrs.topics"
                        :key="topic.id"
                        :style="{ fontFamily: projectConfig.fontFamily }"
                    >
                        <font-awesome-icon
                            :icon="['fal', topic.icon]"
                            class="mr-2"
                        />
                        {{ topic.label }}
                    </p>
                </AccordionItem>
            </Accordion>

            <slot></slot>
        </div>
    </div>
</template>

<script>
import Accordion from './Accordion';
import AccordionItem from './AccordionItem';
import Thumbnail from './Thumbnail';
// import VStyle from './VStyle';
import workActivityTimePeriodText from '~/utils/workActivityTimePeriodText';

export default {
    name: 'WorkActivity',
    components: {
        Accordion,
        AccordionItem,
        Thumbnail,
        // VStyle,
    },
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props || {},
        };
    },
    computed: {
        style() {
            return {
                backgroundColor: this.getColor(this.attrs.icon_color),
                color: this.getColor(this.attrs.icon_inner_color || 'light'),
            };
        },
        css() {
            return '<style>' + this.attrs.css + '</style>';
        },
        summaryData() {
            return {
                parentUUID: this.componentData.uuid,
                props: {
                    title: 'Summary',
                    header_color: this.attrs.icon_color,
                    header_text: this.attrs.icon_inner_color || 'light',
                    header_size: 'lg',
                },
            };
        },
    },
    methods: {
        workActivityTimePeriodText,
    },
};
</script>

<style lang="scss" scoped>
.work-activity {
    max-height: 100%;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    background: #fff;

    & > div.content {
        overflow: auto;
    }

    .heading-container {
        .heading {
            display: flex;
            align-items: center;

            i {
                cursor: pointer;
                flex-basis: 2rem;
                text-align: center;
                width: 24px;
            }
        }
    }

    .accordion {
        .fa,
        .svg-inline--fa {
            width: 30px;
        }
    }
}
</style>
