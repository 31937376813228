<template>
    <table border="0" cellpadding="0" cellspacing="0" width="100%">
        <tr>
            <slot></slot>
        </tr>
    </table>
</template>

<script>
/**
 * https://templates.mailchimp.com/development/responsive-email/responsive-column-layouts/
 */
export default {
    name: 'NewsPostColumns',
};
</script>
