var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table',{staticClass:"work-activities",style:({
        width: '100%',
    }),attrs:{"cellpadding":"0","cellspacing":"0","width":"100%","valign":"middle"}},[_c('tbody',{style:({
            width: '100%',
            backgroundColor: '#fff',
        })},_vm._l((_vm.workActivities),function(wa,i){return _c('tr',{key:i,style:({
                width: '100%',
                borderBottom: '16px solid #fff',
            })},[_c('td',{attrs:{"align":"center","valign":"center"}},[_c('Spacer'),_vm._v(" "),_c('table',{staticStyle:{"width":"100%"},attrs:{"cellpadding":"0","cellspacing":"0","border":"0","role":"presentation"}},[_c('tr',[_c('td',{staticClass:"work-activity-column-container",staticStyle:{"width":"300px"},attrs:{"valign":"top","align":"center"}},[_c('div',{style:({
                                    display: 'inline-block',
                                    'vertical-align': 'middle',
                                })},[_c('table',{staticClass:"work-activity-img-column",staticStyle:{"width":"300px"},attrs:{"cellpadding":"0","cellspacing":"0","border":"0","role":"presentation"}},[_c('tr',[_c('td',{style:({
                                                verticalAlign: 'top',
                                                border: '1px solid #f3f6f8',
                                            }),attrs:{"align":"center"}},[_c('a',{attrs:{"href":wa.metadata.props
                                                        .preview_url}},[_c('img',{style:({
                                                        width: '300px',
                                                        'max-width':
                                                            '300px',
                                                        display: 'block',
                                                        border: '5px solid #d3e5f2',
                                                        'box-sizing':
                                                            'border-box',
                                                    }),attrs:{"src":wa.published_map_url ||
                                                        wa.draft_map_url,"width":"300","height":"300","border":"0"}})])])])])])]),_vm._v(" "),_c('td',{staticClass:"work-activity-column-container",staticStyle:{"width":"300px"},style:({ backgroundColor: '#f3f6f8' }),attrs:{"valign":"middle","align":"center"}},[_c('div',{style:({
                                    display: 'inline-block',
                                    'vertical-align': 'middle',
                                })},[_c('table',{staticStyle:{"width":"300px"},style:({
                                        'Margin-left': 'auto',
                                        'Margin-right': 'auto',
                                        'border-spacing': '0 !important',
                                        'mso-table-lspace': '0pt',
                                        'mso-table-rspace': '0pt',
                                        'border-collapse':
                                            'separate!important',
                                    }),attrs:{"cellpadding":"0","cellspacing":"0","border":"0","role":"presentation","align":"center"}},[_c('tr',[_c('td',{style:({
                                                backgroundColor: '#f3f6f8',
                                                paddingTop: '8px',
                                                paddingBottom: '16px',
                                                color: _vm.getColor(
                                                    'secondary'
                                                ),
                                                paddingLeft: '16px',
                                                paddingRight: '16px',
                                            }),attrs:{"align":"center","valign":"middle"}},[_c('h3',{style:({
                                                    fontFamily:
                                                        _vm.projectConfig.fontFamily,
                                                    margin: '8px 0',
                                                    color: _vm.getColor('dark'),
                                                })},[_vm._v("\n                                                "+_vm._s(wa.name)+"\n                                            ")]),_vm._v(" "),_c('p',{style:({
                                                    fontFamily:
                                                        _vm.projectConfig.fontFamily,
                                                    margin: '0 0 8px 0',
                                                })},[_vm._v("\n                                                •\n                                                "+_vm._s(wa.metadata.props
                                                        .location)+"\n                                            ")]),_vm._v(" "),_c('p',{style:({
                                                    fontFamily:
                                                        _vm.projectConfig.fontFamily,
                                                    margin: '0 0 8px 0',
                                                })},[_vm._v("\n                                                •\n                                                "+_vm._s(_vm.$moment(
                                                        new Date(
                                                            wa.metadata.props.date_start
                                                        )
                                                    ).format(
                                                        _vm.$config.WORK_ACTIVITY_DATE_FORMAT
                                                    ))+"\n                                                -\n                                                "+_vm._s(wa.metadata.props
                                                        .date_end
                                                        ? _vm.$moment(
                                                              new Date(
                                                                  wa.metadata.props.date_end
                                                              )
                                                          ).format(
                                                              _vm.$config.WORK_ACTIVITY_DATE_FORMAT
                                                          )
                                                        : 'ongoing')+"\n                                            ")]),_vm._v(" "),(
                                                    wa.metadata.props
                                                        .description
                                                )?_c('p',{style:({
                                                    margin: '0 0 8px 0',
                                                    fontFamily:
                                                        _vm.projectConfig.fontFamily,
                                                })},[_vm._v("\n                                                "+_vm._s(wa.metadata.props
                                                        .description)+"\n                                            ")]):_vm._e(),_vm._v(" "),_c('table',{attrs:{"cellpadding":"0","cellspacing":"0"}},[_c('tbody',[_c('tr',[_c('ButtonLink',{attrs:{"component-data":{
                                                                value: 'View on portal map',
                                                                props: {
                                                                    block: false,
                                                                    href: wa
                                                                        .metadata
                                                                        .props
                                                                        .preview_url,
                                                                    text: '#fff',
                                                                    bg_color:
                                                                        'brand_secondary',
                                                                },
                                                            }}})],1)])])])])])])])])]),_vm._v(" "),_c('Spacer')],1)])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }