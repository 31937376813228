// NOT YET EMAIL OPTIMISED
import CalendarBtn from '../CalendarBtn';
import Campaign from '../Campaign';
import Column from '../Column';
import Columns from '../Columns';
import CopyrightText from '../CopyrightText';
import DownloadBtn from '../DownloadBtn';
import FeedbackBtn from '../FeedbackBtn';
import HtmlBlock from '../HtmlBlock';
import Hero from '../Hero';
import Icon from '../Icon';
import IconImage from '../IconImage';
import InlineText from '../InlineText';
import MakeSubmission from '../MakeSubmission';
import MessageBanner from '../MessageBanner';
import Modal from '../Modal';
import ModalGeneric from '../ModalGeneric';
import Modals from '../Modals';
import NewsPost from '../NewsPost';
import NewsPosts from '../NewsPosts';
import Page from '../Page';
import PageFooter from '../PageFooter';
import PageHeader from '../PageHeader';
import PageSection from '../PageSection';
import Tab from '../Tab';
import Tabs from '../Tabs';
import Thumbnail from '../Thumbnail';
import Topic from '../Topic';
import Topics from '../Topics';
import Percent from '../Percent';
import NewsPostColumns from '../NewsPostColumns';
import NewsPostColumn from '../NewsPostColumn';
import Form from '../form/Form';
import MediaCollection from '../MediaCollection';
import NewsPostSection from '../NewsPostSection.vue';
import CampaignPreFooter from './CampaignPreFooter.vue';
import MediaLoader from './Media';
import WorkActivities from './WorkActivities.vue'; // EMAIL OPTIMISED
import Youtube from './Youtube.vue'; // EMAIL OPTIMISED
import Translation from './Translation.vue'; // EMAIL OPTIMISED
import SocialIcons from './SocialIcons.vue'; // EMAIL OPTIMISED
import Paragraph from './Paragraph.vue'; // EMAIL OPTIMISED
import Media from './Media.vue'; // EMAIL OPTIMISED
import Hr from './Hr.vue'; // EMAIL OPTIMISED
import Heading from './Heading.vue'; // EMAIL OPTIMISED
import GalleryItem from './GalleryItem.vue'; // EMAIL OPTIMISED
import Gallery from './Gallery.vue'; // EMAIL OPTIMISED
import CompareSlider from './CompareSlider.vue'; // EMAIL OPTIMISED
import CampaignFooter from './CampaignFooter.vue'; // EMAIL OPTIMISED
import Carousel from './Carousel.vue';
import CardTitle from './CardTitle.vue'; // Not litmus tested
import CardBody from './CardBody.vue'; // Not litmus tested
import Card from './Card.vue'; // Not litmus tested
import Cards from './Cards.vue'; // Not litmus tested
import ButtonLinks from './ButtonLinks.vue';
import ButtonLink from './ButtonLink.vue';
import BlockQuote from './BlockQuote.vue';
import AccordionItem from './AccordionItem.vue';
import Accordion from './Accordion.vue';
import Spacer from './Spacer.vue';

const emailComponents = {
    EmailNewsPostSection: NewsPostSection,
    EmailSpacer: Spacer,
    EmailForm: Form,
    EmailMediaCollection: MediaCollection,
    EmailCampaignPreFooter: CampaignPreFooter,
    EmailNewsPostColumns: NewsPostColumns,
    EmailNewsPostColumn: NewsPostColumn,
    EmailAccordion: Accordion,
    EmailAccordionItem: AccordionItem,
    EmailBlockQuote: BlockQuote,
    EmailButtonLink: ButtonLink,
    EmailButtonLinks: ButtonLinks,
    EmailCarousel: Carousel,
    EmailCampaignFooter: CampaignFooter,
    EmailCompareSlider: CompareSlider,
    EmailGallery: Gallery,
    EmailGalleryItem: GalleryItem,
    EmailHeading: Heading,
    EmailHr: Hr,
    EmailMedia: Media,
    EmailParagraph: Paragraph,
    EmailSlide: Media,
    EmailSocialIcons: SocialIcons,
    EmailTranslation: Translation,
    EmailYoutube: Youtube,
    EmailWorkActivities: WorkActivities,
    EmailCalendarBtn: CalendarBtn,
    EmailCampaign: Campaign,
    EmailCard: Card,
    EmailCardBody: CardBody,
    EmailCards: Cards,
    EmailCardTitle: CardTitle,
    EmailColumn: Column,
    EmailColumns: Columns,
    EmailCopyrightText: CopyrightText,
    EmailDownloadBtn: DownloadBtn,
    EmailFeedbackBtn: FeedbackBtn,
    EmailHtmlBlock: HtmlBlock,
    EmailHero: Hero,
    EmailIcon: Icon,
    EmailIconImage: IconImage,
    EmailInlineText: InlineText,
    EmailMakeSubmission: MakeSubmission,
    EmailMediaLoader: MediaLoader,
    EmailMessageBanner: MessageBanner,
    EmailModal: Modal,
    EmailModalGeneric: ModalGeneric,
    EmailModals: Modals,
    EmailNewsPost: NewsPost,
    EmailNewsPosts: NewsPosts,
    EmailPage: Page,
    EmailPageFooter: PageFooter,
    EmailPageHeader: PageHeader,
    EmailPageSection: PageSection,
    EmailTab: Tab,
    EmailTabs: Tabs,
    EmailThumbnail: Thumbnail,
    EmailTopic: Topic,
    EmailTopics: Topics,
    EmailPercent: Percent,
};

export default emailComponents;
