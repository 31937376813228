<template>
    <table width="100%" style="width: 100%">
        <tbody width="100%" style="width: 100%">
            <tr width="100%" style="width: 100%">
                <td
                    width="100%"
                    :style="{
                        backgroundColor: getColor('brand_secondary'),
                        borderRadius: '4px',
                        height: '32px',
                        textAlign: 'center',
                        width: '100%',
                    }"
                >
                    <a
                        :href="attrs.src"
                        target="_blank"
                        rel="noopener"
                        :style="{
                            color: '#fff',
                            textAlign: 'center',
                            textDecoration: 'none',
                            verticalAlign: 'middle',
                            lineHeight: '32px',
                            fontFamily: projectConfig.fontFamily,
                            width: '100%',
                        }"
                    >
                        Play Video
                    </a>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
export default {
    name: 'Youtube',
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props || {},
        };
    },
};
</script>
