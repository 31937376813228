var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',{style:({
        borderSpacing: '16px',
        verticalAlign: 'top',
        border: '1px solid #f3f6f8',
    })},[(_vm.item.props.media && _vm.item.props.media.url)?[_c('div',{staticClass:"media-item-container"},[_c('img',{staticStyle:{"width":"100%","max-width":"334px","display":"block","border":"0px"},attrs:{"src":_vm.item.props.media.url.replace('?p=original', '') +
                    '?w=334',"border":"0","height":"auto"}})])]:(_vm.item.props.media.before)?_c('table',{staticStyle:{"height":"100%"},attrs:{"cellpadding":"0","cellspacing":"0","width":"100%","border":"0","height":"100%"}},[_c('tbody',[_c('tr',{style:({
                    marginBottom: '16px',
                })},[_c('td',{style:({
                        width: '50%',
                        backgroundColor: '#f3f6f8',
                    })},[_c('div',{style:({
                            margin: '12px',
                            textAlign: 'center',
                        })},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.item.props.media.before.label)}})]),_vm._v(" "),_c('div',{style:({
                            width: '100%',
                            paddingTop: '56.25%',
                            backgroundPosition: 'center center',
                            backgroundSize: 'cover',
                            backgroundImage: ("url(" + (_vm.item.props.media.before.url) + ")"),
                        })})]),_vm._v(" "),_c('td',{style:({
                        width: '50%',
                        backgroundColor: '#f3f6f8',
                    })},[_c('div',{style:({
                            margin: '12px',
                            textAlign: 'center',
                        })},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.item.props.media.after.label)}})]),_vm._v(" "),_c('div',{style:({
                            width: '100%',
                            paddingTop: '56.25%',
                            backgroundPosition: 'center center',
                            backgroundSize: 'cover',
                            backgroundImage: ("url(" + (_vm.item.props.media.after.url) + ")"),
                        })})])])])]):_c('div',{style:({
            backgroundColor: _vm.getColor('primary'),
            margin: '0 8px 8px 0',
            borderRadius: '4px',
            display: 'inline-block',
            height: '100%',
            border: '16px rgba(0,0,0,0)',
        })},[_c('a',{style:({
                color: _vm.getColor('light'),
                fontFamily: _vm.projectConfig.fontFamily,
            }),attrs:{"href":'https://media.caapp.com.au/' + _vm.item.props.media.ref,"target":"_blank"}},[_vm._v("\n            Play Video\n        ")])]),_vm._v(" "),(_vm.item.props.caption)?_c('div',[_c('div',{staticStyle:{"margin":"12px"}},[_c('p',{style:({ fontFamily: _vm.projectConfig.fontFamily })},[_vm._v("\n                "+_vm._s(_vm.item.props.caption)+"\n            ")])])]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }