<template>
    <div>
        <DragHandle />

        <div :id="elementID" class="links">
            <draggable
                v-if="isPreview && !previewMode"
                :value="componentData.children"
                class="links-container"
                :class="classList"
                group="buttonLinks"
                @change="refreshPreviewOrder(componentData.uuid)"
            >
                <slot></slot>
            </draggable>

            <div v-else class="links-container" :class="classList">
                <slot></slot>
            </div>
        </div>

        <Adder
            :component-data="componentData.parentData"
            :child-u-u-i-d="componentData.uuid"
        />
    </div>
</template>

<script>
import helpers from '../mixins/helpers';
import Adder from './editor/Adder';
import DragHandle from './editor/DragHandle';
export default {
    name: 'ButtonLinks',
    components: {
        Adder,
        DragHandle,
    },
    mixins: [helpers],
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props || {},
        };
    },
    computed: {
        classList() {
            const alignment = this.attrs.align;
            return [
                this.attrs ? this.attrs.element_class : '',
                'flex-' + alignment,
                `justify-content-${this.attrs.justify_content}`,
                ...this.helperClasses,
            ];
        },
        elementID() {
            return this.attrs ? this.attrs.element_id : '';
        },
    },
};
</script>

<style lang="scss" scoped>
.align-items-left {
    .links,
    .links > div:not(.button-link-container) {
        justify-content: flex-start;
    }
}

.align-items-center {
    .links,
    .links > div:not(.button-link-container) {
        justify-content: center;
    }
}

.align-items-right {
    .links,
    .links > div:not(.button-link-container) {
        justify-content: flex-end;
    }
}

.links-container {
    display: flex;
    width: 100%;
    position: relative;
    z-index: 0;

    &.flex-row {
        flex-wrap: wrap;
    }

    &.flex-stacked {
        flex-direction: column;
        align-items: flex-start;
    }

    &:hover {
        .drag-handle,
        .adder {
            opacity: 1;
        }
    }

    .links,
    .links > div:not(.button-link-container) {
        margin: 0 auto 0.25em auto;
        display: flex;
        position: relative;
        width: 100%;
        flex-wrap: wrap;

        & > .btn {
            margin: 0 0.25rem 0.25rem 0;
        }
    }
}
</style>
