import slugify from 'slugify';

import { startCase, camelCase } from 'lodash-es';

import hexAToRGBA from './hexAToRGBA';

function getColorSlug(key: string) {
    if (!key) {
        return key;
    }
    const slug = slugify(key, {
        lower: true,
        strict: false,
        trim: true,
        remove: /[^\w\s/$*_+~.()'"!\-:@]+/g,
    });
    if (slug && slug?.replaceAll) {
        return slug.replaceAll('/', '-');
    }
    return slug;
}
function isCssColor(input: string): boolean {
    if (!input) {
        return false;
    }
    const _input = input.trim();
    return ['rgb(', 'rgba(', 'hsl(', 'hsla(', '#'].some((word) =>
        _input.startsWith(word),
    );
}

/**
 * Convert color name to hex code.
 * @param {*} input color name
 * @returns hex code
 */
function getColor(input?: any, colours?: Record<string, string>) {
    if (!input) {
        return input;
    }

    const _input = input.trim();

    const legacyColorKeys = [
        'brand',
        'brand_secondary',
        'primary',
        'tertiary',
        'danger',
        'orange',
        'pink',
        'warning',
        'success',
        'purple',
        'dark',
        'secondary',
        'light',
    ];
    const colorName = legacyColorKeys.includes(_input)
        ? startCase(camelCase(_input)) + ' colour'
        : _input;

    // Mapbox currently doesn't support hex code with opacity, transform to rgba
    if (_input.startsWith('#') && (_input.length > 7 || _input.length === 5)) {
        return hexAToRGBA(_input);
    }

    /**
     * Return the input if it's already a color value.
     */
    if (isCssColor(_input)) {
        return _input;
    }

    /**
     * Get color from CSS vars
     */
    if (typeof getComputedStyle !== 'undefined') {
        const slug = getColorSlug(colorName);
        // https://stackoverflow.com/a/41725782
        const color = getComputedStyle(document.body)
            .getPropertyValue(`--ca-${slug}`)
            .trim();
        if (isCssColor(color)) {
            return color;
        }
    }

    /**
     * Check and return colour from colour dictionary if it exists.
     */
    const _colours =
        typeof colours !== 'undefined' && colours
            ? colours
            : globalThis.colours;
    if (_colours) {
        const color = _colours[colorName] ?? _colours[_input];
        if (color) {
            return color;
        }
    }
}
export default getColor;
