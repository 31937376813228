var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"workActivity",staticClass:"work-activity"},[_c('head',{domProps:{"innerHTML":_vm._s(_vm.css)}}),_vm._v(" "),_c('div',{staticClass:"heading-container p-3",style:(_vm.style)},[_c('div',{staticClass:"heading d-flex"},[_c('h5',{staticClass:"my-2",style:({ fontFamily: _vm.projectConfig.fontFamily })},[_c('strong',[_vm._v(_vm._s(_vm.attrs.title))])])]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.attrs.show_description && _vm.attrs.description),expression:"attrs.show_description && attrs.description"}]},[_vm._v("\n            "+_vm._s(_vm.attrs.description)+"\n        ")]),_vm._v(" "),_c('h6',{directives:[{name:"show",rawName:"v-show",value:(_vm.attrs.ref),expression:"attrs.ref"}],staticClass:"mb-2",style:({ fontFamily: _vm.projectConfig.fontFamily })},[_c('em',[_vm._v("Notification No. "+_vm._s(_vm.attrs.ref))])])]),_vm._v(" "),_c('div',{staticClass:"content p-3"},[(_vm.attrs.thumbnail)?_c('Thumbnail',{staticClass:"mb-3",attrs:{"component-data":{
                props: {
                    media: _vm.attrs.thumbnail,
                },
            }}}):_vm._e(),_vm._v(" "),_c('Accordion',{attrs:{"editable":false}},[_c('AccordionItem',{attrs:{"visible":true,"editable":false,"component-data":_vm.summaryData}},[_c('p',{style:({ fontFamily: _vm.projectConfig.fontFamily })},[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":['fal', 'map-marker-alt']}}),_vm._v("\n                    "+_vm._s(_vm.attrs.location)+"\n                ")],1),_vm._v(" "),_c('p',{style:({ fontFamily: _vm.projectConfig.fontFamily })},[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":['fal', 'calendar']}}),_vm._v("\n                    "+_vm._s(_vm.$moment(new Date(_vm.attrs.date_start))
                            .tz('UTC')
                            .format(_vm.$config.WORK_ACTIVITY_DATE_FORMAT))+"\n                    -\n                    "+_vm._s(_vm.attrs.date_end
                            ? _vm.$moment(new Date(_vm.attrs.date_end))
                                  .tz('UTC')
                                  .format(_vm.$config.WORK_ACTIVITY_DATE_FORMAT)
                            : 'ongoing')+"\n                ")],1),_vm._v(" "),(_vm.attrs.time_desc)?_vm._l((_vm.attrs.time_desc.filter(function (tp) { return tp; })),function(tp){return _c('p',{key:tp.uuid,style:({ fontFamily: _vm.projectConfig.fontFamily })},[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":['fal', 'clock']}}),_vm._v("\n                        "+_vm._s(_vm.workActivityTimePeriodText(tp))+"\n                    ")],1)}):_vm._e(),_vm._v(" "),_vm._l((_vm.attrs.topics),function(topic){return _c('p',{key:topic.id,style:({ fontFamily: _vm.projectConfig.fontFamily })},[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":['fal', topic.icon]}}),_vm._v("\n                    "+_vm._s(topic.label)+"\n                ")],1)})],2)],1),_vm._v(" "),_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }