<template>
    <div v-if="_items.length > 0" class="map-legend">
        <draggable
            v-if="edit"
            v-model="_items"
            class="keys edit"
            draggable=".key"
            style="cursor: pointer"
        >
            <div v-for="item in _items" :key="item.key" class="key">
                <font-awesome-icon :icon="['fal', 'bars']" />
                <LegendItem
                    v-bind="item"
                    style="display: inline-block"
                ></LegendItem>
            </div>
        </draggable>
        <div v-else class="keys">
            <LegendItem
                v-for="(item, index) in _items"
                :key="item.key"
                class="key"
                :class="{ 'mt-3': index !== 0 }"
                v-bind="item"
            >
            </LegendItem>
        </div>
    </div>
</template>

<script>
import { uniqBy } from 'lodash-es';
import LegendItem from '@/components/map/LegendItem.vue';
import getColor from '~/utils/getColor';
export default {
    name: 'Legend',
    components: {
        LegendItem,
    },
    props: {
        features: { type: Array, default: () => [] },
        value: { type: Array },
        edit: { type: Boolean, default: false },
    },
    computed: {
        _features() {
            return JSON.parse(JSON.stringify(this.features));
        },
        _items: {
            get() {
                const vm = this;
                const features = this.features ?? [];
                const generated = this.generateLegend(features);
                if (vm.value) {
                    const merged = uniqBy(vm.value, 'id').filter((element) =>
                        generated.find(
                            (value) =>
                                value.key === element.key ||
                                value.id === element.id,
                        ),
                    );
                    generated.forEach((element) => {
                        const found = vm.value.findIndex(
                            (value) =>
                                value.key === element.key ||
                                value.id === element.id,
                        );
                        if (found === -1) {
                            merged.push(element);
                        } else {
                            vm.$set(merged, found, element);
                        }
                    });
                    return uniqBy(merged, 'key').filter(
                        (e) => e && e.type && e.metadata?.mode !== 'alias',
                    );
                }
                return (vm.value ?? generated).filter(
                    (f) => f?.metadata?.mode !== 'alias',
                );
            },
            set(value) {
                this.$emit('input', value);
            },
        },
    },
    watch: {
        _features() {
            this.$emit('input', this._items);
        },
    },
    methods: {
        getColor({ paint, properties }) {
            const color =
                paint['line-color'] ?? paint['fill-color'] ?? properties.color;
            return getColor(color);
        },
        generateLegend(features) {
            const vm = this;
            return uniqBy(
                features
                    .filter(
                        ({ properties: { legend }, metadata }) =>
                            legend !== false && metadata?.mode !== 'alias',
                    )
                    .map(
                        ({ id, source_type: type, paint = {}, properties }) => {
                            const color = vm.getColor({
                                paint,
                                properties,
                            });
                            const label = properties.label ?? properties.title;
                            const pattern =
                                paint['fill-pattern'] ??
                                properties['fill-pattern'];
                            const opacity =
                                paint['fill-opacity'] ??
                                paint['line-opacity'] ??
                                1;
                            const pointBorderColor =
                                type === 'Point'
                                    ? properties.icon_inner_color || 'light'
                                    : null;
                            const borderColor = getColor(
                                paint['fill-outline-color'] ??
                                    properties['fill-outline-color'] ??
                                    pointBorderColor ??
                                    color,
                            );

                            const borderWidth =
                                type === 'Point'
                                    ? 1
                                    : properties['fill-outline-width'];
                            const lineWidth = paint['line-width'];
                            const lineStyle =
                                paint['line-dasharray'] &&
                                paint['line-dasharray'].length > 1
                                    ? 'dashed'
                                    : 'solid';
                            const iconValue = properties.icon_value;
                            const lineIconFilename = `line_${
                                paint['line-dasharray'] &&
                                paint['line-dasharray'].length > 1
                                    ? 'short'
                                    : 'none'
                            }_${
                                properties.show_arrowheads
                                    ? properties.show_arrowheads.toLowerCase()
                                    : 'none'
                            }`;
                            const iconText = properties.icon_text;
                            const iconTextColor = getColor(
                                properties.icon_inner_color,
                            );
                            const iconColor = properties.icon_inner_color;
                            const key = `${label}-${color}`;
                            return {
                                iconColor,
                                iconTextColor,
                                iconText,
                                iconValue,
                                lineIconFilename,
                                label,
                                color,
                                type,
                                opacity,
                                pattern,
                                lineWidth,
                                lineStyle,
                                borderColor,
                                borderWidth,
                                key,
                                id,
                            };
                        },
                    ),
                'key',
            );
        },
    },
};
</script>

<style lang="scss">
.map-legend {
    .heading {
        padding: 0.5rem;

        h6 {
            font-size: 0.8rem;
            color: $light;
        }
    }

    .keys {
        white-space: normal;
        .key {
            p {
                font-size: 12px;
                display: inline-block;
                margin-right: 0;
                width: max-content;
                max-width: 300px;
            }

            span {
                text-align: center;
                display: inline-block;
            }
        }
        &.edit {
            .key {
                padding: 0.75rem 1rem 0.5rem 1rem;
                margin-bottom: 0.75rem;
                background: #303f4b;
                border-radius: 4px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                min-height: 45px;

                span {
                    text-align: left;
                }

                p {
                    font-size: 16px;
                    display: inline;
                }
            }

            .fa-trash {
                margin-left: 1rem;
            }

            .fa-bars {
                margin-right: 1rem;
            }

            .fa-trash,
            .fa-bars {
                transition: 0.3s ease;
                cursor: pointer;

                &:hover {
                    filter: brightness(1.2);
                }
            }
        }
    }
}
#mapKey .dropdown-item {
    padding: 0.5rem;
    cursor: default;
    pointer-events: none;
}
</style>
