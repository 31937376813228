var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.attrs.layout === 'carousel')?_c('div',{staticClass:"carousel-item"},[_c('div',{staticClass:"ca-contents-content ca-card card",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"thumbnail-container ca-thumbnail ca-contents-content-thumbnail"},[_c('b-img-lazy',{staticClass:"img-fluid",attrs:{"src":_vm.np.metadata.props.thumbnail
                        ? _vm.np.metadata.props.thumbnail.url
                        : 'https://ca-v2.s3-ap-southeast-2.amazonaws.com/placeholder.jpg',"alt":"","width":"640","height":"360","loading":"lazy"}})],1),_vm._v(" "),_c('div',{staticClass:"ca-contents-content-cardbody ca-cardbody card-body"},[(_vm.np.published_status === 'draft')?_c('div',{staticClass:"draft-banner"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'exclamation-triangle']}}),_vm._v(" "),_c('span',[_vm._v("Note this draft News Post will not appear in published\n                    content. Please publish this News Post to include it in\n                    your content.")])],1):_vm._e(),_vm._v(" "),_c('h5',{staticClass:"ca-heading ca-contents-content-title",style:({
                    fontFamily: _vm.projectConfig.fontFamily,
                })},[_vm._v("\n                "+_vm._s(_vm.np.metadata.props.title)+"\n            ")]),_vm._v(" "),(_vm.attrs.show_date && _vm.np.custom_date && _vm.np.published_at)?_c('p',{staticClass:"ca-contents-content-date",style:({
                    fontFamily: _vm.projectConfig.fontFamily,
                })},[_vm._v("\n                "+_vm._s(_vm.$moment(
                        new Date(_vm.np.custom_date) ||
                            new Date(_vm.np.published_at)
                    ).format('D MMMM, YYYY'))+"\n            ")]):_vm._e(),_vm._v(" "),(_vm.description)?_c('p',{staticClass:"description ca-contents-content-excerpt text-truncate-description",staticStyle:{"overflow":"hidden","display":"-webkit-box","-webkit-line-clamp":"3","-webkit-box-orient":"vertical"},style:({
                    fontFamily: _vm.projectConfig.fontFamily,
                })},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.description)}})]):_vm._e(),_vm._v(" "),_vm._m(0)])])]):_c('div',{staticClass:"news-post ca-contents-content ca-card card"},[_c('a',{staticClass:"ca-content-content-link",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"thumbnail-container ca-thumbnail ca-contents-content-thumbnail"},[_c('b-img-lazy',{staticClass:"img-fluid",attrs:{"src":_vm.thumbnail,"alt":"","width":"640","height":"360","loading":"lazy"}})],1),_vm._v(" "),_c('div',{staticClass:"content ca-contents-content-cardbody ca-cardbody card-body"},[(_vm.np.published_status === 'draft')?_c('div',{staticClass:"draft-banner"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'exclamation-triangle']}}),_vm._v(" "),_c('span',[_vm._v("Note this draft News Post will not appear in published\n                    content. Please publish this News Post to include it in\n                    your content.")])],1):_vm._e(),_vm._v(" "),_c('h5',{staticClass:"ca-heading ca-contents-content-title",style:({
                    fontFamily: _vm.projectConfig.fontFamily,
                })},[_vm._v("\n                "+_vm._s(_vm.np.metadata.props.title)+"\n            ")]),_vm._v(" "),(_vm.attrs.show_date && _vm.np.custom_date && _vm.np.published_at)?_c('p',{staticClass:"ca-contents-content-date",style:({
                    fontFamily: _vm.projectConfig.fontFamily,
                })},[_vm._v("\n                "+_vm._s(_vm.$moment(
                        new Date(_vm.np.custom_date) ||
                            new Date(_vm.np.published_at)
                    ).format('D MMMM, YYYY'))+"\n            ")]):_vm._e(),_vm._v(" "),(_vm.description)?_c('p',{staticClass:"description ca-contents-content-excerpt text-truncate-description",staticStyle:{"overflow":"hidden","display":"-webkit-box","-webkit-line-clamp":"3","-webkit-box-orient":"vertical"},style:({
                    fontFamily: _vm.projectConfig.fontFamily,
                })},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.description)}})]):_vm._e(),_vm._v(" "),_vm._m(1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ca-contents-content-excerpt ca-contents-content-excerpt-read-more"},[_c('span',{staticClass:"ca-excerpt-read-more"},[_vm._v("Read more "),_c('i',{staticClass:"fa fa-long-arrow-right"})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ca-contents-content-excerpt ca-contents-content-excerpt-read-more"},[_c('span',{staticClass:"ca-excerpt-read-more"},[_vm._v("Read more "),_c('i',{staticClass:"fa fa-long-arrow-right"})])])}]

export { render, staticRenderFns }